import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from "./App";
import { HashRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const root = ReactDOMClient.createRoot(document.getElementById("root"));

const mdTheme = createTheme();

root.render(
        <ThemeProvider theme={mdTheme}>
          <HashRouter>
            <App/>
          </HashRouter>
        </ThemeProvider>
)
