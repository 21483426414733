import * as React from 'react';
import { useState, useEffect } from 'react';
import RadioButtons from '../components/RadioButtons/RadioButtons';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

// import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import CellMenu from '../components/CellMenu';
import DynamicForm from '../components/DynamicForm/DynamicForm';
import AddJobForm from '../components/AddJobForm/AddJobForm';
import SearchBar from '../components/SearchBar/SearchBar';
import "./Jobs.css";
import CommentsView from '../components/CommentsView/CommentsView';
import io from 'socket.io-client';
import logo from "../../assets/blue-gray-logo.png";
import jsPDF from 'jspdf';
import AmiriFont from "../../assets/Amiri Regular-normal";
import CircularProgress from '@mui/material/CircularProgress';
import Dropdown from '../components/DropdownBar/Dropdown';
import { flexbox } from '@mui/system';
import { useNavigate } from 'react-router-dom';



const JobsTable = (props) => {
  
  console.log(props.jobs)

  function preventDefault(event) {
    event.preventDefault();
  }

  const handleCellDelete = (id) => {
    props.handleDelete(id);
  }

  const handleCellEdit = (id) => {
    props.handleEdit(id);
  }

  const handleCellComments = (id) => {
    props.handleComments(id);
  }

  const handleCellPrint = (id) => {
    props.handlePrint(id);
  }

  const handleSelectedJob = (id) => {
    props.handleSelect(id);
  }

  const parseDate = (date) => {
    return new Date(date)
    .toLocaleString('en-US', { timeZone: 'Asia/Riyadh' });
  }

  const renderRows = () => {

    // if (props.searchQry.length > 0 && props.filter != "") {
    //   let filteredJobs = null;
    //   if (props.job_title == "admin") {
    //     filteredJobs = props.jobs.filter(job => job[props.filter].toString().toLowerCase().includes(props.searchQry));
    //   } else {
    //     filteredJobs = props.jobs.filter(job => job[props.filter].toString().toLowerCase().includes(props.searchQry) &&  job[props.job_title.toLowerCase()]== props.employeeName);
    //   }
      return props.jobs.map((job) => (
        <TableRow key={job.job_id}>
          <TableCell align='center'>{job.job_id}</TableCell>
          <TableCell>{parseDate(job.date_created)}</TableCell>
          <TableCell sx={{minWidth: 125}}>{job.job_title}</TableCell>
          <TableCell>{job.salesman}</TableCell>
          <TableCell>{job.customer_name}</TableCell>
          <TableCell>{job.technician}</TableCell>
          <TableCell>
            <p className={`status-${job.status.toLowerCase()}`}>{job.status}</p>
          </TableCell>
          <TableCell>{job.created_by}</TableCell>
          <CellMenu id={job.job_id} handleSelect={handleSelectedJob} onClick={handleSelectedJob} comments={true} printable={true} handleEdit={handleCellEdit} handleDelete={handleCellDelete} handleComments={handleCellComments} handlePrint={handleCellPrint}/>
        </TableRow>
      ));
    // }

    // let filteredJobs = props.jobs;
    // if (props.jobs) {
    //   console.log(props.jobs[0])
    //   console.log(props.job_title)
    // }
    
    // if (props.job_title != "admin") {
    //   console.log(props.employeeName);
    //   filteredJobs = props.jobs.filter(job => job[props.job_title.toLowerCase()]== props.employeeName);
    // }

    // console.log(filteredJobs)
    // return filteredJobs.map((job) => (
    //     <TableRow key={job.job_id}>
    //       <TableCell align='center'>{job.job_id}</TableCell>
    //       <TableCell>{parseDate(job.date_created)}</TableCell>
    //       <TableCell>{job.job_title}</TableCell>
    //       <TableCell>{job.salesman}</TableCell>
    //       <TableCell>{job.customer_name}</TableCell>
    //       <TableCell>{job.technician}</TableCell>
    //       <TableCell>
    //         <p className={`status-${job.status.toLowerCase()}`}>{job.status}</p>
    //       </TableCell>
    //       <TableCell>{job.created_by}</TableCell>
    //       <CellMenu id={job.job_id} handleSelect={handleSelectedJob} comments={true} printable={true} handleEdit={handleCellEdit} handleDelete={handleCellDelete} handleComments={handleCellComments} handlePrint={handleCellPrint}/>
    //   </TableRow>
    // ));
  }


  return(
    <React.Fragment>
    <Title>Current Jobs</Title>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Job ID</TableCell>
          <TableCell>Date Created</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Salesman</TableCell>
          <TableCell>Customer Name</TableCell>
          <TableCell>Technician</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Created By</TableCell>
        </TableRow>
      </TableHead>
      {props.isTableLoading ? <CircularProgress/> : 
        <TableBody>
          {renderRows()}
        </TableBody>
      }
    </Table>
  </React.Fragment>
  );
}


const Jobs = (props) => {

  const jobFormFields = { 
  job_title: {
    id: "job_title",
    label: "Job Title",
    type: "text",
  },
  salesman: {
    id: "salesman",
    label: "Salesman",
    type: "select"
  },
  technician: {
    id: "technician",
    label: "Technician",
    type: "select"
  },
  status: {
    id: "status",
    label: "Status",
    type: "select"
  }
};


const filterOptions = [{
    label: "ID",
    value: "job_id",
  }, {
    label: "Date Created",
    value: "date_created"
  }, {
    label: "Job Title",
    value: "job_title"
  }, {
    label: "Salesman",
    value: "salesman"
  }, {
    label: "Customer Name",
    value: "customer_name"
  }, {
    label: "Technician",
    value: "technician"
  }, {
    label: "Status",
    value: "status"
  }
]

  const [jobs, setJobs] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [editingJob, setEditingJob] = useState(null);
  const [commentingJob, setCommentingJob] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("")

  const [commentsView, setCommentsView] = useState(false);
  const [printView, setPrintView] = useState(false);

  const [currentPage, setCurrentPage] = useState(1)
  const [manualPage, setManualPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [isTableLoading, setLoadingTable] = useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    console.log("Jobs USE EFFECT!");
    // const socket = io(`https://alinmaa-backend-nodejs-cleardb.herokuapp.com`, {auth: {
    //   token: authToken
    // }});

    fetchJobs(search, selectedFilter, currentPage);

    // TODO: FIX SOCKETS
    // socket.on('jobUpdate', () => {
    //   fetchJobs(search, selectedFilter, currentPage);
    // });

    // return () => {
    //   socket.close();
    // };
  }, []);


  const fetchJobs = async(search, selectedFilter, currentPage) => {

    setLoadingTable(true);

    const options = {
      method: 'GET',
      credentials: 'include'
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/jobs/getJobs?search=${search}&filter=${selectedFilter}&page=${currentPage}`, options)
        .then((res) => {
          if (res.status == 403) {
            alert("ession expired. Redirecting to login for re-authentication.")
            setTimeout(() => navigate("/"), 2000);
          }
          if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.statusText}`)
          }
          return res;
        })
        .then((res) => res.json())
        .then(json => {
          setJobs(json.data);
          setTotalPages(json.totalPages)
        })
        .catch(err => alert(err));

    setLoadingTable(false);
  }

  const handleNewJobFromInquiry = () => {
  }
  


  const handleJobAddition = async(job) => {
    const addJobUrl = `${process.env.REACT_APP_API_URL}/api/jobs/addJob`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: 'include',
      body: JSON.stringify(job),
    }

    const response = await fetch(addJobUrl, options);
    if (response.ok) {  
      const responseData = await response.json();
      job.job_id = responseData.job_id;
      job.date_created = responseData.date_created;
      setJobs(prev => [job, ...prev]);
    } else if (response.status == 403) {
        alert("ession expired. Redirecting to login for re-authentication.")
        setTimeout(() => navigate("/"), 2000);
      } else {
      const errorText = await response.text();
      alert(errorText);
    }
  }

  const handleCellComments = (id) => {
    setCommentsView(true);
  }

  const handleCommentsClose = () => {
    setCommentsView(false);
  }

  const handlePrintClose = () => {
    setPrintView(false);
  }

  const handleCellEdit = async(id) => {
    // const job = jobs.filter(job => job.job_id == id)[0];
    // setEditingJob({
    //   job_id: job.job_id,
    //   date_created: job.date_created,
    //   job_title: job.job_title,
    //   salesman: job.salesman,
    //   customer_name: job.customer_name,
    //   technician: job.technician,
    //   status: job.status
    // });
    setIsEditing(true);
  }

  const applyCellEdits = async(editedJob) => {
    const editJobUrl = `${process.env.REACT_APP_API_URL}/api/jobs/editJob`;
    editedJob.job_id = selectedJob.job_id;


    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: 'include',
      body: JSON.stringify(editedJob),
    }

    const response = await fetch(editJobUrl, options);
    if (response.ok) {  
      const updatedJobs = jobs.map(job => {
        if (job.job_id == editedJob.job_id) {
          return editedJob
        }
        return job;
      });
      setJobs(updatedJobs);
    } else if (response.status == 403) {
      alert("ession expired. Redirecting to login for re-authentication.")
      setTimeout(() => navigate("/"), 2000);
    }
    
    else {
      const errorText = await response.text();
      alert(`Error editing job: ${errorText}`);
    }
  }

  const handleCellDelete = async(id) => {
    const deleteCustomerUrl = `${process.env.REACT_APP_API_URL}/api/jobs/deleteJob`;

    let body = {
        "job_id": id
      };

    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' 
        },
        credentials: 'include',
        body: JSON.stringify(body)
    }

    const response = await fetch(deleteCustomerUrl, options);
    if (response.ok) {
        setJobs(jobs.filter(job => job.job_id != id));
    } else if (response.status == 403) {
        alert("Session expired. Redirecting to login for re-authentication.")
        setTimeout(() => navigate("/"), 2000);
    }
    
    else {
        const errorText = await response.text();
        alert(`Failed to delete job: ${errorText}`);
    }
  }

  const handleSelectedJob = (id) => {
    const job = jobs.filter(job => job.job_id == id)[0];
    setSelectedJob({
      job_id: job.job_id,
      date_created: job.date_created,
      job_title: job.job_title,
      salesman: job.salesman,
      customer_name: job.customer_name,
      technician: job.technician,
      status: job.status
    });
  }

  const handleSearch = (searchQry) => {
    if (selectedFilter) {
      setCurrentPage(1);
      setManualPage(1);
      setSelectedSearch(searchQry);
      fetchJobs(searchQry, selectedFilter, currentPage);
    } else {
      alert("Choose a search filter!");
    }
  }

  const parseNumDate = (date) => {
    return new Date(date).toLocaleString('en-US', {timeZone: 'Etc/GMT+4', day: 'numeric', month: 'numeric', year: 'numeric'});
  }

  const fetchCustomer = async () => {

    const authToken = sessionStorage.getItem("accessToken");
    const fetchCustomerUrl = `${process.env.REACT_APP_API_URL}/api/customers/getCustomerByName?${new URLSearchParams({customer_name: selectedJob.customer_name})}`;
    const options = {
      method: 'GET',
      credentials: 'include'
    }
 
    fetch(fetchCustomerUrl, options)
    .then(res => res.json())
    .then(data => generatePdf(data))
    .catch(err => console.log(err));
  }

  const generatePdf = (customerInfo) => {
    var doc = new jsPDF({
      orientation: 'p',
      format: 'a4',
    });

    doc.setProperties({
      title: `${selectedJob.job_title}`
    })

    var width = doc.internal.pageSize.getWidth();

    const headerImg = new Image();
    headerImg.src = logo;

    doc.setFontSize(30)
    doc.setFont("Amiri Regular");
    doc.text("Job Order", 10, 15);
    doc.text(`No. ${selectedJob.job_id}`, 10, 30);

    doc.line(10, 50, width - 10, 50);

    doc.setFontSize(14);

    doc.text(`Date: ${parseNumDate(selectedJob.date_created)}`, 10, 65);
    doc.addImage(headerImg, 'png', 105, 0, 100, 50);

    doc.setFontSize(20);

    doc.text(`Title: ${selectedJob.job_title}`, 10, 75);

    doc.setFontSize(14);

    doc.text(`Customer: ${selectedJob.customer_name}`, 10, 95);
    doc.text(`Address: ${customerInfo.customer_address}`, 130, 95);

    doc.text(`Contact: ${customerInfo.contact_name}`, 10, 105);
    doc.text(`Tel: ${customerInfo.customer_tel}`, 130, 105);


    doc.line(10, 120, width - 10, 120);


    doc.text(`Salesman: ${selectedJob.salesman}`, 10, 135);
    doc.text(`Technician: ${selectedJob.technician}`, 10, 145);

    doc.text(`Type:`, 130, 130);
    doc.setFontSize(8);

    doc.rect(130, 135, 3, 3);
    doc.text(`Delivery + Installation`, 135, 138);

    doc.rect(130, 140, 3, 3);
    doc.text(`Installation only`, 135, 143);

    doc.rect(130, 145, 3, 3);
    doc.text(`Maintenance`, 135, 148);

    doc.rect(130, 150, 3, 3);
    doc.text(`Fabric Replacement`, 135, 153);

    doc.setFontSize(14);

    doc.text(`Notes:`, 10, 170);
    doc.text(`ETD:`, 130, 170);

    doc.setLineDashPattern([5, 2], 20);

    doc.line(10, 180, 120, 180);
    doc.line(10, 190, 120, 190);
    doc.line(10, 200, 120, 200);

    doc.setLineDashPattern([], 0);

    doc.text(`Salesman Signature`, 10, 220);
    doc.line(10, 240, 70, 240);

    doc.text(`Manager Signature`, 10, 260);
    doc.line(10, 280, 70, 280);

    doc.text(`Date`, 130, 260);
    doc.line(130, 280, 160, 280);

    window.open(URL.createObjectURL(doc.output('blob')));
  }

  const handleCellPrint = (id) => {
    setPrintView(true);
    fetchCustomer();
  }

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  }


    const toggleDrawer = () => {
      setOpen(!open);
    };

    const goToPage = (e, page) => {
      if ((e.target.innerText == "Prev" && currentPage != 1) || (e.target.innerText == "Next" && currentPage != totalPages)) {
        setCurrentPage(page);
        setManualPage(page);
        fetchJobs(selectedSearch, selectedFilter, page);
      }
    }

    const handleManualPageChange = (e) => {
      let page = parseInt(e.target.value);
      if (page > totalPages) {
        page = totalPages;
      } else if (page < 1) {
        page = 1;
      }
      if (!isNaN(page)) {
        setManualPage(page);
      } else {
        setManualPage("");
      }
    }
  
    const handleManualPageClick = (e) => {
      console.log(e.key)
      if (e.key == "Enter" && manualPage != "") {
        setCurrentPage(manualPage);
        fetchJobs(search, selectedFilter, manualPage);
      }
    }
      
        return (
          <ThemeProvider theme={createTheme()}>
            <Box sx={{ display: 'flex', width: "100%" }}>
              <CssBaseline />
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                }}
              >
                <Toolbar />
                
                <div className='search-add-container-jobs'>

                  <div className='search-filter-container'>
                    <SearchBar className='search-bar'
                                placeholder = 'Search'
                                onChange={(value) => setSearch(value.toLowerCase())}
                                onSearch={handleSearch}
                                value={search}
                        />


                      {props.isMobile ? <Dropdown selected={selectedFilter} onSelect={handleFilterChange} selections={filterOptions}></Dropdown> : null}
                      {props.isMobile ? null :             
                        <div className='filter-container'>
                          <Container sx={{ width: 800, mr: "auto", ml: -1.25, mt: 0, boxShadow: 3, height: 40, bgcolor: "white", borderRadius: 2, display: "flex", alignItems: "center"}}>
                                    <RadioButtons id={"jobs"}  options={filterOptions} value={selectedFilter} onChange={handleFilterChange}></RadioButtons>
                          </Container>
                        </div>
                      }

                    </div>

                  <div className='add-customer-button'>
                    <AddJobForm formType={"Add Job"}
                                formTitle={"Add Job"} 
                                formFields={jobFormFields}
                                employeeName={props.userCreds.user_name}
                                employeeJobTitle={props.userCreds.job_title}
                                newJobFromInquiry={props.newJobFromInquiry}
                                onSubmit={handleJobAddition}>

                    </AddJobForm>
                  </div>
                </div>
                <div className='row-containers'>

                  <Container maxWidth="xlg" sx={{ mt: 4, mb: 4, ml: "auto", mr: "auto"}}>
                    <Grid container spacing={3} zeroMinWidth>
                      {/* Recent Orders */}
                      <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "scroll" }}>
                          <JobsTable      jobs={jobs}
                                          searchQry={search}
                                          filter={selectedFilter} 
                                          handleEdit={handleCellEdit}
                                          handleComments={handleCellComments}
                                          handleDelete={handleCellDelete}
                                          handlePrint={handleCellPrint}
                                          handleSelect={handleSelectedJob}
                                          job_title={props.userCreds.job_title}
                                          employeeName={props.userCreds.user_name}
                                          isLoading={isTableLoading}/>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Container>
                </div>



                {isEditing && <AddJobForm type={"edit"} 
                                          isEditing={isEditing} 
                                          formTitle={"Edit Job"} 
                                          formFields={jobFormFields} 
                                          employeeName={props.userCreds.user_name}
                                          editable={selectedJob} 
                                          onEditClose={() => setIsEditing(false)}
                                          onSubmit={applyCellEdits}>
                              </AddJobForm>}

                {commentsView && <CommentsView open={commentsView} onClose={handleCommentsClose} job={selectedJob} userCreds={props.userCreds} isMobile={props.isMobile}></CommentsView>}

                {/* {printView && <PrintView open={printView} onClose={handlePrintClose} job={selectedJob} userCreds={props.userCreds}></PrintView>} */}

                <div className='pagination-bar'>
                  <p className={currentPage === 1 ? "pag-button-disabled" : "pag-button"} onClick={(e) => goToPage(e, currentPage - 1)}>
                    Prev
                  </p>

                  <div className='pagination-page-num'>{`Page `}
                    
                      <input type="number"
                      className='no-arrows'
                      value={manualPage}
                      onChange={(e) => handleManualPageChange(e)}
                      onKeyDown={(e) => handleManualPageClick(e)}
                      min="1"
                      max={totalPages}
                      style={{ textAlign: 'center' }}
                    />
                    
                    
                    {` of ${totalPages}`}
                  </div>

                  <div>
                    <p className={currentPage == totalPages ? "pag-button-disabled" : "pag-button"} onClick={(e) => goToPage(e, currentPage + 1)} disabled={currentPage == totalPages}>
                      Next
                    </p>
                  </div>
                </div>

              </Box>
            </Box>
          </ThemeProvider>
        );

}

export default Jobs;