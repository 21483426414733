import React from "react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Customers from "./pages/Dashboard/Customers";
import NavBar from "./pages/components/NavBar/NavBar";
import TopBar from "./pages/components/TopBar/TopBar";
import { useState, useEffect } from "react";
import Jobs from "./pages/Dashboard/Jobs";
import { Box } from "@mui/system";
import ProtectedRoute from "./pages/components/ProtectedRoute/ProtectedRoute";
import Popup from "./pages/components/Popup/Popup";
import Inquiries from "./pages/Dashboard/Inquiries";
import Reports from "./pages/Reports";

export default function App() {

  const [open, setOpen] = useState(false);
  const location = useLocation().pathname;

  const [popUp, setPopUp] = useState(false);

  const [userCreds, setUserCreds] = useState({user_name: "Ghaith Boksmati", job_title: "admin"});

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [currentInquiryToJob, setCurrentInquiryToJob] = useState();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLoginCredentials = (creds) => {
    setUserCreds(creds);
  }

  console.log(location)

  return (
      <div className="">
        <Routes>
          <Route path="/" element={<Login onLogin={handleLoginCredentials}/>}/>
        </Routes>

        {location !== '/' && <Box sx={{ display: 'flex', width: "100%" }}>
          <NavBar open={open} toggleDrawer={toggleDrawer}></NavBar>
          <TopBar open={open} toggleDrawer={toggleDrawer} currentTab={location.substring(1)}></TopBar>
          <Routes>
            <Route element={<ProtectedRoute/>}>
              <Route path="/home" element={<Home userCreds={userCreds} popUp={popUp}/>}/>
              <Route path="/customers" element={<Customers isMobile={isMobile} popUp={popUp}/>}/>
              <Route path="/jobs" element={<Jobs userCreds={userCreds} isMobile={isMobile} popUp={popUp} newJobFromInquiry={currentInquiryToJob}/>}/>
              <Route path="/inquiries" element={<Inquiries userCreds={userCreds} isMobile={isMobile} popUp={popUp} onInquiryToJob={(id, customerName) => setCurrentInquiryToJob({id: id, customerName: customerName})}/>}/>
              <Route path="/reports" element={<Reports userCreds={userCreds} isMobile={isMobile}/>}/>
            </Route>
          </Routes>
        </Box>}
      </div>
  )
}