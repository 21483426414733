import React from "react";
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PersonIcon from '@mui/icons-material/Person';
import Container from '@mui/material/Container';
import TwoWayArrow from "../../../assets/two-way-arrow.png";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ChatView from "../ChatView/ChatView";
import io from 'socket.io-client';
import { useNavigate } from "react-router-dom";

import "./CommentsView.css";

const CommentsView = (props) => {

  const navigate = useNavigate();
  const [comments, setComments] = useState([]);

  const parseComments = (newComments) => {
    const coms = [];
    newComments.map(comment => (
      coms.push({
        id: comment.comment_id,
        position: props.userCreds.user_name == comment.sender_name ? "left" : "right",
        text: comment.comment,
        title: comment.sender_name,
        date: comment.date_created,
        type: "text"
      })
    ))
    setComments(coms);
  }

  useEffect(() => {

    const authToken = sessionStorage.getItem("accessToken");
    // const socket = io(`${process.env.REACT_APP_API_URL}`, {auth: {
    //   token: authToken
    // }});

    const fetchMessages = async () => {
      const fetchMessagesUrl = `${process.env.REACT_APP_API_URL}/api/messages/getMessages?${new URLSearchParams({job_id: props.job.job_id})}`;
      const options = {
        method: 'GET',
        credentials: 'include'
      }

      fetch(fetchMessagesUrl, options)
      .then((res) => {
        if (res.status == 403) {
          navigate("/");
        }
        if (!res.ok) {
          throw new Error(`Failed to fetch: ${res.statusText}`)
        }
        return res;
      })
      .then(res => res.json())
      .then(data => parseComments(data))
      .catch(err => console.log(err));
    }

    fetchMessages();

    // socket.on('messageUpdate', () => {
    //   fetchMessages();
    // });

    // return () => {
    //   socket.close();
    // };
  }, [])

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
};

  const handleClose = () => {
    setOpen(false);
  }

  const handleFormSubmit = () => {

  }

  const handleMessageSent = async(message) => {
    const addMsgUrl = `${process.env.REACT_APP_API_URL}/api/messages/addMessage`;
    const dateAdded =  new Date(Date.now()).toLocaleString();
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: 'include',
      body: JSON.stringify({job_id: props.job.job_id, sender_name: props.userCreds.user_name, date_created: dateAdded, comment: message})
    }

    const response = await fetch(addMsgUrl, options);
    if (response.ok) {
      const data = await response.json();
      const newMessage = {
        id: data.comment_id,
        position: "left",
        text: message,
        title: props.userCreds.user_name,
        date: dateAdded,
        type: "text"
      }

      setComments(prev => [...prev, newMessage]);
    } else {
      console.log("Could not send message. Please try again");
    }
  }

  const handleKeyDown = (message) => {
    handleMessageSent(message);
  }

  const parseDate = (date) => {
    return new Date(date)
    .toLocaleString('en-US', { timeZone: 'Asia/Riyadh' });
  }

  // Return Function

  if (props.isMobile) {
    return (
        <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth={'md'}>
        <DialogTitle>{props.formTitle}</DialogTitle>
        <DialogContent className='add-customer-modal-container'>
          <div className="comments-view-container">
            <p className="section-title">Job Details</p>
            <Container maxWidth={"md"} sx={{boxShadow: 3, padding: 2, fontSize: 14, bgcolor: "#ffffff", mb: 3}}>
              <div className="details-header-mobile">
                <p className="mobile-job-id"># {props.job.job_id}</p>
                <p className="mobile-date">{parseDate(props.job.date_created)}</p>
              </div>
                
                
                <p><span className="mb-hd-bold">Job Title:</span> {props.job.job_title}</p>
                <p><span className="mb-hd-bold">Customer Name:</span> {props.job.customer_name}</p>
                <p><span className="mb-hd-bold">Salesman:</span> {props.job.customer_name}</p>
                <p><span className="mb-hd-bold">Technician:</span> {props.job.customer_name}</p>
                <p className={props.job.status == "Closed" ? "status-mobile-closed" : "status-mobile-ordered"}> {props.job.status}</p>
            </Container>


            <p className="section-title">Comments History</p>
            <Container maxWidth={"md"} sx={{boxShadow: 3, bgcolor: "#ffffff", mt: 2}}>

              <div className="comments-container">
                <ChatView messages={comments} handleMessageSent={handleMessageSent} handleKeyDown={handleKeyDown}>

                </ChatView>
              </div>
            </Container>


          </div>
        </DialogContent>



        <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
        {/* <Button onClick={handleFormSubmit}>{props.isEditing ? "Apply Changes" : "Add"}</Button> */}
        </DialogActions>
    </Dialog>
    )
  } else {

  }
  return (
    <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>{props.formTitle}</DialogTitle>
      <DialogContent className='add-customer-modal-container'>
        <div className="comments-view-container">
          <p className="section-title">Job Details</p>
          <Container maxWidth={"md"} sx={{boxShadow: 3, bgcolor: "#ffffff", mb: 5}}>
            <div className="job-details-header">
              <div className="job-title-id-wrapper">
                <p className="job-number">{props.job.job_id}</p>
                <p className="job-title">{props.job.job_title}</p>
              </div>

              <p className="job-date">{parseDate(props.job.date_created)}</p>
            </div>

            <div className="customer-name-container">
                <div className="customer-name-icon-wrapper">
                  <PersonIcon fontSize="medium" sx={{mt: 0.2}}></PersonIcon>
                  <p className="customer-name">{props.job.customer_name}</p>
                </div>
            </div>

            <div className="sales-tech-container">
              <div className="sales-tech-wrapper">
                <div className="party">
                  <p className="salesman">Salesman</p>
                  <p>{props.job.salesman}</p>
                </div>

                <CompareArrowsIcon color="#3894cc" sx={{width: 125, height: 125, ml: 15, mr: 15}}></CompareArrowsIcon>

                <div className="party">
                  <p className="technician">Technician</p>
                  <p>{props.job.technician}</p>
                </div>
              </div>
            </div>

            <div className="status-container">
                <p className="job-status">{props.job.status}</p>
            </div>
          </Container>


          <p className="section-title">Comments History</p>
          <Container maxWidth={"md"} sx={{boxShadow: 3, bgcolor: "#ffffff", mt: 2}}>

            <div className="comments-container">
              <ChatView messages={comments} handleMessageSent={handleMessageSent} handleKeyDown={handleKeyDown}>

              </ChatView>
            </div>
          </Container>


        </div>
      </DialogContent>



      <DialogActions>
      <Button onClick={props.onClose}>Close</Button>
      {/* <Button onClick={handleFormSubmit}>{props.isEditing ? "Apply Changes" : "Add"}</Button> */}
      </DialogActions>
  </Dialog>
  )
}

export default CommentsView;