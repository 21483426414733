import React from "react";
import Dashboard from "./Dashboard/Dashboard";

const Home = (props) => {
    console.log(props.username);
    return(
            <Dashboard userCreds={props.userCreds}></Dashboard>  
    )
}

export default Home;