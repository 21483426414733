import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';
import { flexbox, fontSize } from '@mui/system';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Label } from 'recharts';

export default function RadioButtons(props) {


  const handleChange = (event) => {
    props.onChange(event);
  };

  const renderRadioButtons = () => {
    return props.options.map(option => (
       <FormControlLabel key={`${option.value}${props.id}`} value={option.value} control={<Radio size='small'/>} label={<Typography sx={{fontSize: 14}}>{option.label}</Typography>} />
    ));
  }

  return (
      <FormControl sx={{display: 'flex', flexDirection: "row", mt: "auto", mb: "auto"}}>
        <FormLabel sx={{fontSize: 14, mt: "auto", mb: "auto"}}>Filter By..</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={props.value}
          onChange={handleChange}
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 12,
            },
            ml: 1.5
          }}
        >
          {renderRadioButtons()}
        </RadioGroup>
      </FormControl>
  );
}