import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import "./CellMenu.css";

const CellMenu = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      props.handleSelect(props.id);
    };
    const handleEdit = (e) => {
      setAnchorEl(null);
      props.handleEdit(e.target.id);
    };

    const handleDelete = (e) => {
      setAnchorEl(null);
      props.handleDelete(e.target.id);
    };

    const handleComments = (e) => {
      setAnchorEl(null);
      props.handleComments(e.target.id);
    }

    const handlePrint = (e) => {
      setAnchorEl(null);
      props.handlePrint(e.target.id);
    }

    const handleInquiryToJob = (e) => {
      setAnchorEl(null);
      props.handleInquiryToJob(e.target.id);
    }

    const handleClose = () => {
        setAnchorEl(null);
        // props.handleEdit(e.target.id);
    }

        return (
            <div id={props.id} className="row-menu">
            <MoreHorizIcon fontSize='small' onClick={handleClick}/>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem id={props.id} onClick={handleEdit}>Edit</MenuItem>
              {props.comments && <MenuItem id={props.id} onClick={handleComments}>View Comments</MenuItem>}
              {props.printable && <MenuItem id={props.id} onClick={handlePrint}>Print</MenuItem>}
              {props.inqMove && <MenuItem id={props.id} onClick={handleInquiryToJob}>Move to Jobs</MenuItem>}
              <MenuItem id={props.id} onClick={handleDelete}>Delete</MenuItem>
            </Menu>
          </div>
        );
      }

export default CellMenu;