import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CountUp from 'react-countup';

import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import IconButton from '@mui/material/IconButton';
import { TripOrigin } from '@mui/icons-material';
import "./Dashboard.css";
import { useNavigate } from 'react-router-dom';
import Popup from '../components/Popup/Popup';

export function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent(props) {



  const [open, setOpen] = React.useState(false);
  const [jobCount, setJobCount] = React.useState("");
  const [orderCount, setOrderCount] = React.useState("");

  const navigate = useNavigate();

  const setStats = (data) => {
    setJobCount(data.job_count);
    setOrderCount(data.order_count);
  }

  React.useEffect(() => {
    console.log("Dashboard USEEFFECT!");

    const fetchDashboardStats = async() => {
      const dashboardStatsUrl = `${process.env.REACT_APP_API_URL}/api/jobs/getDashboardStats`;

      const body = {
        employee_name: props.userCreds.user_name,
        job_title: props.userCreds.job_title,
      }
  
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json' 
        },
        credentials: 'include',
        //body: JSON.stringify(body)
      }

      fetch(dashboardStatsUrl, options)
      .then((res) => {
        if (res.status == 403) {
          alert("Session expired. Redirecting to login for re-authentication.")
          navigate("/");
        }
        if (!res.ok) {
          throw new Error(`Failed to fetch: ${res.statusText}`)
        }
        return res;
      })
      .then(res => res.json())
      .then(data => setStats(data))
      .catch(err => alert(err))
    }

    fetchDashboardStats();

  }, [])

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={createTheme()}>
    <Box sx={{ display: 'flex', width: "100%" }}>
      <CssBaseline />
        <div className='row-containers'>

          <Container maxWidth="lg" sx={{ mt: 12, mb: 4, ml: 0 }}>
            <div className="welcome-text-container">
              <p className='welcome-text'>Hello <span className='user-name-welcome'>{props.userCreds.user_name}</span>,</p>
            </div>

            <div className='total-jobs-container'>

            <div className='job-container'>
                <p className="job-snip-title">Total Jobs</p>
                <div className='count-up-wrapper'>
                  <CountUp className='job-snip-text' start={0} end={jobCount} delay={0} duration={1.5}></CountUp>
                </div>
              </div>

              <div className='job-container'>
                <p className='job-snip-title'>Total Orders</p>
                <div className='count-up-wrapper'>
                  <CountUp className='job-snip-text' start={0} end={orderCount} delay={0} duration={1.5}></CountUp>
                </div>
              </div>
            </div>

          </Container>
        </div>
        <Popup></Popup>
    </Box>
  </ThemeProvider>
  );
}

export default function Dashboard(props) {

  return <DashboardContent userCreds={props.userCreds} />;
}
