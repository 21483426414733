import React from "react";
import { useState, useRef } from "react";
import "react-chat-elements/dist/main.css";
import { MessageList, MessageBox, Input } from "react-chat-elements";
import SendIcon from '@mui/icons-material/Send';
import { IconButton } from "@mui/material";
import "./ChatView.css";

const ChatView = (props) => {

  const messages = props.messages;
  const [currentMessage, setCurrentMessage] = useState("");
  const inputRef = useRef();

  const renderMessages = () => {

  }

  const handleMessageSent = (e) => {
    props.handleMessageSent(currentMessage);
    setCurrentMessage("");
    inputRef.current.value = "";
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      props.handleKeyDown(e.target.value)
      setCurrentMessage("");
      inputRef.current.value = "";
    }

  }

  const handleInputChange = (e) => {
    setCurrentMessage(e.target.value);
  }

  return (  
    <div className="chat-container">
    <MessageList
      lockable={true}
      dataSource={messages}
      ></MessageList>

      <div className="chat-footer">
        <Input
          referance={inputRef}
          placeholder="Type here..."
          onChange={(e) => handleInputChange(e)}
          onKeyDown={(e) => handleKeyDown(e)}>
        </Input>

        <IconButton onClick={handleMessageSent}>
          <SendIcon color={"primary"}></SendIcon>
        </IconButton>



      </div>



    </div>)
  


}

export default ChatView;