import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import "./SearchBar.css";


const SearchBar = ({
    value, placeholder, onChange, onSearch
}) => {

    const handleSearchClick = () => {
        onSearch(value);
    }

    const handleEnterKey = (e) => {
        if (e.key == "Enter") {
            handleSearchClick()
        }
    }

    return (
        <div className="search-bar-container">
            <input className="search-input"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                    onKeyDown={handleEnterKey}
                    >
            </input>

            <IconButton sx={{ml: "auto"}} onClick={() => handleSearchClick()}>
                <SearchIcon/>
            </IconButton>
        </div>
    )
}

export default SearchBar;