import * as React from 'react';
import { useState, useEffect } from 'react';
import RadioButtons from '../components/RadioButtons/RadioButtons';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

// import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import CellMenu from '../components/CellMenu';
import DynamicForm from '../components/DynamicForm/DynamicForm';
import SearchBar from '../components/SearchBar/SearchBar';
import "./Customers.css";
import io from 'socket.io-client';
import CircularProgress from '@mui/material/CircularProgress';
import Dropdown from '../components/DropdownBar/Dropdown';
import { useNavigate } from 'react-router-dom';


const CustomersTable = (props) => {
  
  function preventDefault(event) {
    event.preventDefault();
  }

  const handleCellDelete = (id) => {
    props.handleDelete(id);
  }

  const handleCellEdit = (id) => {
    console.log(id);
    props.handleEdit(id);
  }

  const renderRows = () => {
    // console.log(`filter = ${props.filter}`);
    // console.log(`qry = ${props.searchQry}`)
    // if (props.searchQry.length > 0 && props.filter != "") {
    //   const filteredCustomers = props.customers.filter(customer => customer[props.filter].toString().toLowerCase().includes(props.searchQry));
    //   return filteredCustomers.map((customer) => (
    //     <TableRow key={customer.customer_id}>
    //       <TableCell align='center'>{customer.customer_id}</TableCell>
    //       <TableCell>{customer.customer_name}</TableCell>
    //       <TableCell>{customer.contact_name}</TableCell>
    //       <TableCell>{customer.customer_email}</TableCell>
    //       <TableCell>{customer.customer_tel}</TableCell>
    //       <TableCell>{customer.customer_address}</TableCell>
    //       <CellMenu id={customer.customer_id} handleEdit={handleCellEdit} handleDelete={handleCellDelete}/>
    //     </TableRow>
    //   ));
    // }

    return props.customers.map((customer) => (
      <TableRow key={customer.customer_id}>
        <TableCell align='center'>{customer.customer_id}</TableCell>
        <TableCell sx={{minWidth: 125}}>{customer.customer_name}</TableCell>
        <TableCell sx={{minWidth: 100}}>{customer.contact_name}</TableCell>
        <TableCell>{customer.customer_email}</TableCell>
        <TableCell>{customer.customer_tel}</TableCell>
        <TableCell sx={{minWidth: 125}}>{customer.customer_address}</TableCell>
        <CellMenu id={customer.customer_id} handleEdit={handleCellEdit} handleDelete={handleCellDelete}/>
      </TableRow>
    ));
  }


  return(
    <React.Fragment>
    <Title>Current Customers</Title>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Customer ID</TableCell>
          <TableCell>Customer Name</TableCell>
          <TableCell>Contact Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Telephone</TableCell>
          <TableCell>Address</TableCell>
        </TableRow>
      </TableHead>
      {props.isTableLoading ? <CircularProgress/> : 
        <TableBody>
          {renderRows()}
        </TableBody>
      }
    </Table>
  </React.Fragment>
  );
}


const Customers = (props) => {

  const customerFormFields = { 
  name: {
    id: "name",
    label: "Customer Name",
    type: "text",
    },
    contact_name: {
      id: "contact_name",
      label: "Contact Name",
      type: "text"
    },
    email: {
      id: "email",
      label: "Email",
      type: "email"
    },
    tel: {
      id: "tel",
      label: "Telephone Number",
      type: "tel"
    },
    address: {
      id: "address",
      label: "Address",
      type: "text"
    },
  };

  const filterOptions = [{
      label: "ID",
      value: "customer_id",
    }, {
      label: "Name",
      value: "customer_name"
    }, {
      label: "Telephone ",
      value: "customer_tel"
    }, {
      label: "Address",
      value: "customer_address"
    }
  ]

  const [currentPage, setCurrentPage] = useState(1)
  const [manualPage, setManualPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)


  const mdTheme = createTheme();
  const [customers, setCustomers] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");
  const [isTableLoading, setLoadingTable] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log("Customers USE EFFECT!");
    // const socket = io(`https://alinmaa-backend-nodejs-cleardb.herokuapp.com`, {auth: {
    //   token: authToken
    // }});



    fetchCustomers(search, selectedFilter, currentPage);

    // socket.on('customerUpdate', () => {
    //   fetchCustomers();
    // });

    // return () => {
    //   socket.close();
    // };
  }, []);

  
  const fetchCustomers = async(search, selectedFilter, currentPage) => {

    setLoadingTable(true);

    const options = {
      method: 'GET',
      credentials: 'include'
    }
    fetch(`${process.env.REACT_APP_API_URL}/api/customers/getCustomers?search=${search}&filter=${selectedFilter}&page=${currentPage}`, options)
        .then((res) => {
          if (res.status == 403) {
            alert("Session expired. Redirecting to login for re-authentication.")
            setTimeout(() => navigate("/"), 2000);
          }
          if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.statusText}`)
          }
          return res;
        })
        .then((res) => res.json())
        .then(json => {
          console.log(json.data)
          setCustomers(json.data)
          setTotalPages(json.totalPages)
        })
        .catch(err => alert(err))
    }

  const handleCustomerAddition = async(customer) => {
    const addCustomerUrl = `${process.env.REACT_APP_API_URL}/api/customers/addCustomer`;

    const body = {
      customer_name: customer.name,
      contact_name: customer.contact_name,
      customer_email: customer.email,
      customer_tel: customer.tel,
      customer_address: customer.address
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }

    const response = await fetch(addCustomerUrl, options);
    if (response.ok) {  
      const responseData = await response.json();
      body.customer_id = responseData.customer_id;
      setCustomers(prev => [...prev, body]);
    } else {
      if (response.status == 403) {
        alert("Session expired. Redirecting to login for re-authentication.")
        setTimeout(() => navigate("/"), 2000);
      } else {
        const errorText = await response.text();
        alert(`Failed to add customer: ${errorText}`);
      }
    }
  }

  const handleCellEdit = async(id) => {
    console.log(id);
    const customer = customers.filter(customer => customer.customer_id == id)[0];
    console.log(customer);
    setEditingCustomer({
      id: customer.customer_id,
      name: customer.customer_name,
      contact_name: customer.contact_name,
      email: customer.customer_email,
      tel: customer.customer_tel,
      address: customer.customer_address
    });
    setIsEditing(true);
  }

  const applyCellEdits = async(customer) => {
    const addCustomerUrl = `${process.env.REACT_APP_API_URL}/api/customers/editCustomer`;
    const customerID = editingCustomer.id;

    const body = {
      customer_id: customerID,
      customer_name: customer.name,
      contact_name: customer.contact_name,
      customer_email: customer.email,
      customer_tel: customer.tel,
      customer_address: customer.address
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: "include",
      body: JSON.stringify(body),
    }

    const response = await fetch(addCustomerUrl, options);
    if (response.ok) {  
      const updatedCustomers = customers.map(customer => {
        if (customer.customer_id == customerID) {
          return body
        }
        return customer;
      });
      setCustomers(updatedCustomers);
    } else {
      if (response.status == 403) {
        alert("Session expired. Redirecting to login for re-authentication.")
        setTimeout(() => navigate("/"), 2000);
      } else {
        const errorText = await response.text();
        alert(`Failed to edit customer: ${errorText}`);
      }
    }
  }

  const handleCellDelete = async(id) => {
    const deleteCustomerUrl = `${process.env.REACT_APP_API_URL}/api/customers/deleteCustomer`;

    let body = {
        "customer_id": id
      };

    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' 
        },
        credentials: 'include',
        body: JSON.stringify(body)
    }

    const response = await fetch(deleteCustomerUrl, options);
    if (response.ok) {
        setCustomers(customers.filter(customer => customer.customer_id != id));
    } else if (response.status == 403) { 
        alert("Session expired. Redirecting to login for re-authentication.")
        setTimeout(() => navigate("/"), 2000);
    } else {
      const errorText = await response.text();
      alert(`Failed to delete customer: ${errorText}`);
    }
    
  }

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  }

  const goToPage = (e, page) => {
    if ((e.target.innerText == "Prev" && currentPage != 1) || (e.target.innerText == "Next" && currentPage != totalPages)) {
      setCurrentPage(page);
      setManualPage(page)
      fetchCustomers(search, selectedFilter, page);
    }
  }

  const handleManualPageChange = (e) => {
    let page = parseInt(e.target.value);
    if (page > totalPages) {
      page = totalPages;
    } else if (page < 1) {
      page = 1;
    }
    if (!isNaN(page)) {
      setManualPage(page);
    } else {
      setManualPage("");
    }
  }

  const handleManualPageClick = (e) => {
    console.log(e.key)
    if (e.key == "Enter" && manualPage != "") {
      setCurrentPage(manualPage);
      fetchCustomers(search, selectedFilter, manualPage);
    }
  }

  const handleSearch = (searchQry) => {
    if (selectedFilter) {
      setCurrentPage(1);
      setManualPage(1);
      setSelectedSearch(searchQry);
      fetchCustomers(searchQry, selectedFilter, currentPage);
    } else {
      alert("Choose a search filter!");
    }
  }

    const toggleDrawer = () => {
      setOpen(!open);
    };
      
        return (
          <ThemeProvider theme={createTheme()}>
            <Box sx={{ display: 'flex', width: "100%" }}>
              <CssBaseline />
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                }}
              >
                <Toolbar />
                
                <div className='search-add-container'>


                  <div className='search-filter-container'>
                    <SearchBar className='search-bar'
                                  placeholder = 'Search'
                                  onChange={(value) => setSearch(value.toLowerCase())}
                                  onSearch={handleSearch}
                                  value={search}

                      />

                    {props.isMobile ? <Dropdown selected={selectedFilter} onSelect={handleFilterChange} selections={filterOptions}></Dropdown> : null}

                    {props.isMobile ? null : 
                      <div className='filter-container'>
                        <Container sx={{ width: 800, mr: "auto", ml: -1.25, mt: 0, boxShadow: 3, height: 40, bgcolor: "white", borderRadius: 2, display: "flex", alignItems: "center"}}>
                            <RadioButtons id={"customers"} options={filterOptions} value={selectedFilter} onChange={handleFilterChange}></RadioButtons>
                        </Container>
                      </div>                          
                    }


                  </div>



                  <div className='add-customer-button'>
                    <DynamicForm formType={"Add Customer"}
                                formTitle={"Add Customer"} 
                                formFields={customerFormFields}
                                onSubmit={handleCustomerAddition}>

                    </DynamicForm>
                  </div>
                </div>

                <div className='row-containers'>

                  <Container maxWidth="xlg" sx={{ mt: 4, mb: 4, ml: "auto", mr:"auto"}}>
                    <Grid container spacing={3}>
                      {/* Recent Orders */}
                      <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "scroll" }}>
                          <CustomersTable customers={customers}
                                          searchQry={search}
                                          filter={selectedFilter} 
                                          handleEdit={handleCellEdit}
                                          handleDelete={handleCellDelete}
                                          isLoading={isTableLoading}/>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Container>
                </div>


                {isEditing && <DynamicForm type={"edit"} 
                                          isEditing={isEditing} 
                                          formTitle={"Edit Customer"} 
                                          formFields={customerFormFields} 
                                          editable={editingCustomer} 
                                          onEditClose={() => setIsEditing(false)}
                                          onSubmit={applyCellEdits}>
                              </DynamicForm>}

                <div className='pagination-bar'>
                  <p className={currentPage === 1 ? "pag-button-disabled" : "pag-button"} onClick={(e) => goToPage(e, currentPage - 1)}>
                    Prev
                  </p>

                  <div className='pagination-page-num'>{`Page `}
                    
                      <input type="number"
                      className='no-arrows'
                      value={manualPage}
                      onChange={(e) => handleManualPageChange(e)}
                      onKeyDown={(e) => handleManualPageClick(e)}
                      min="1"
                      max={totalPages}
                      style={{ textAlign: 'center' }}
                    />
                    
                    
                    {` of ${totalPages}`}
                  </div>
                  
                  <div>
                    <p className={currentPage == totalPages ? "pag-button-disabled" : "pag-button"} onClick={(e) => goToPage(e, currentPage + 1)} disabled={currentPage == totalPages}>
                      Next
                    </p>
                  </div>
                </div>

              </Box>
            </Box>
          </ThemeProvider>
        );

}

export default Customers;