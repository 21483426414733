import Alert from '@mui/material/Alert';
import { Button } from '@mui/material';
import React from "react";
import { useState, useEffect } from 'react';
import "./ErrorPopUp.css";

export default function ErrorPopUp(props) {

if (props.msg !== "") {
  return (
    <div className='error-wrapper'>
      <Alert severity={"error"} onClose={props.onClose}>Error: {props.msg}</Alert>
    </div>

  )
} else {
  return <></>
}


}
