import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';

export const MainListItems = () => {

  const navigate = useNavigate();

  const handleLogOut = () => {
    const logOutUrl = `${process.env.REACT_APP_API_URL}/api/auth/logout`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: 'include',
    }

    fetch(logOutUrl, options)
      .then((res) => {
        if (res.ok) {
          navigate("/")
        }
        else {
          alert("Could not log out. Please try again later.")
        }
      })
  }

  return (
    <React.Fragment>
      <ListItemButton onClick={() => {navigate('/home')}}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard"/>
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/inquiries')}>
        <ListItemIcon>
          <PermPhoneMsgIcon />
        </ListItemIcon>
        <ListItemText primary="Inquiries" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/jobs')}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Jobs" />
      </ListItemButton>
      <ListItemButton onClick={() => {navigate('/customers')}}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers"/>
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/reports')}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Integrations" />
      </ListItemButton>
      <ListItemButton onClick={handleLogOut}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Log out" />
    </ListItemButton>
    </React.Fragment>
  );
}

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>

    
  </React.Fragment>
);
