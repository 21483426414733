import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function Dropdown({
    selected,
    onSelect,
    selections
}) {

    console.log(selections)
  const handleChange = (e) => {
    onSelect(e);
  };

  const renderSelections = () => {
    return selections.map(selection => (
        <MenuItem value={selection.value}>{selection.label}</MenuItem>
    ));
  }

  return (
    <FormControl sx={{ m: 1, mt: 2, mb: 2, minWidth: "75vw", background: "white" }} size="small">
      <InputLabel id="demo-select-small-label">Filter</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selected}
        label="Filter by.."
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {renderSelections()}
      </Select>
    </FormControl>
  );
}
