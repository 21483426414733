import React from "react";
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Select from '@mui/material/Select';
import Select2 from 'react-select';
import { IconButton, Menu } from '@mui/material';
import ErrorPopUp from "../ErrorPopUp/ErrorPopUp";
import { useNavigate } from "react-router-dom";

export default function AddJobForm(props) {
    const [open, setOpen] = useState(false);
    let startingState = {};
    for (const prop in props.formFields) {
        startingState[prop] = "";
    }

    const [salesmen, setSalesmen] = useState([]);
    const [chosenSalesman, setChosenSalesman] = useState("");

    const [customers, setCustomers] = useState([]);
    const [chosenCustomer, setChosenCustomer] = useState("");

    const [technicians, setTechnicians] = useState([]);
    const [chosenTechnician, setChosenTechnician] = useState("");

    const statuses = ["Pending", "Ordered", "Closed"];
    const [chosenStatus, setChosenStatus] = useState("");

    const [enabled, setEnabled] = useState(false);
    const [addMargin, setMargin] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {

        const fetchSalesmen = async() => {

          if (props.employeeJobTitle == "admin") {
            const options = {
              method: 'GET',
              credentials: 'include'
            }
            fetch(`${process.env.REACT_APP_API_URL}/api/employees/getSalesmen`, options)
              .then((res) => {
                if (res.status == 403) {
                  navigate("/");
                }
                if (!res.ok) {
                  throw new Error(`Failed to fetch: ${res.statusText}`)
                }
                return res;
              })
              .then((res) => res.json())
              .then(json => setSalesmen(json))
              .catch(err => console.log(err))
          } else {
            setSalesmen([{employee_name: props.employeeName}])
          }

          }

          const fetchTechnicians = async() => {
            const options = {
              method: 'GET',
              credentials: 'include'
            }

            fetch(`${process.env.REACT_APP_API_URL}/api/employees/getTechnicians`, options)
                .then((res) => {
                  if (res.status == 403) {
                    navigate("/");
                  }
                  if (!res.ok) {
                    throw new Error(`Failed to fetch: ${res.statusText}`)
                  }
                  return res;
                })
                .then((res) => res.json())
                .then(json => setTechnicians(json))
                .catch(err => console.log(err))
          }

          const fetchCustomers = async() => {
            const options = {
              method: 'GET',
              credentials: 'include'
            }

            fetch(`${process.env.REACT_APP_API_URL}/api/customers/getCustomerNames`, options)
                .then((res) => {
                  if (res.status == 403) {
                    navigate("/");
                  }
                  if (!res.ok) {
                    throw new Error(`Failed to fetch: ${res.statusText}`)
                  }
                  return res;
                })
                .then((res) => res.json())
                .then(json => setCustomers(json.map(customer => ({
                    label: customer.customer_name,
                    value: customer.customer_name
                }))))
                .catch(err => console.log(err))
          }
          
      
          fetchSalesmen();
          fetchCustomers();
          fetchTechnicians();
    }, []);

    const [formFields, setFormFields] = useState(startingState);
    console.log(formFields);

    useEffect(() => {
        if (props.isEditing) {
            setFormFields(props.editable);
            setChosenSalesman(props.editable.salesman);
            setChosenCustomer({label: props.editable.customer_name, value: props.editable.customer_name});
            setChosenTechnician(props.editable.technician);
            setChosenStatus(props.editable.status);
        }
    }, [props.isEditing]);

    useEffect(() => {

      if (props.newJobFromInquiry) {
        handleClickOpen();
        setChosenCustomer({label: props.newJobFromInquiry.customerName, value: props.newJobFromInquiry.customerName});
      }
    }, [props.newJobFromInquiry])



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setFormFields(startingState);
        setChosenCustomer("");
        setChosenSalesman("");
        setChosenTechnician("");
        setChosenStatus("");
        setErrorMsg("");
        setOpen(false);
        if (props.isEditing) {
            props.onEditClose();
        }
    };

    const handleFormChange = (e) => {
        const target = e.target.id;
        const value = e.target.value;
        const prev = {...formFields};
        prev[target] = value;
        setFormFields(prev);
    }

    const handleFormSubmit = async() => {
        if (formFields["job_title"].length < 1 || chosenSalesman.length < 1 || (chosenCustomer != "" && chosenCustomer.value.length < 1) || chosenTechnician.length < 1 || chosenStatus.length < 1) {
          setErrorMsg("Please fill all fields to create/edit a job");
          return;
        }
        const newJob = {
          job_title: formFields["job_title"],
          salesman: chosenSalesman,
          customer_name: chosenCustomer.value,
          technician: chosenTechnician,
          status: chosenStatus,
          created_by: props.employeeName
        }

        if (props.isEditing) {
          newJob.date_created = props.editable.date_created;
        }
        props.onSubmit(newJob);
        console.log(`starting state = ${startingState}`);
        handleClose();
    }

    const handleCustomerAddition = async(customer) => {
      const addCustomerUrl = `${process.env.REACT_APP_API_URL}/api/customers/addCustomer`;
  
      const body = {
        customer_name: customer.name,
        contact_name: customer.contact_name,
        customer_email: customer.email,
        customer_tel: customer.tel,
        customer_address: customer.address
      }
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' 
        },
        credentials: 'include',
        body: JSON.stringify(body),
      }
  
      const response = await fetch(addCustomerUrl, options);
      if (response.ok) {  
        const responseData = await response.json();
        body.customer_id = responseData.customer_id;
        setCustomers(prev => [...prev, body]);
      } else {
        if (response.status == 403) {
          alert("Session expired. Redirecting to login for re-authentication.")
          setTimeout(() => navigate("/"), 2000);
        } else {
          const errorText = await response.text();
          alert(`Failed to add customer: ${errorText}`);
        }
      }
    }

    const handleSalesmanChange = (e) => {
        setChosenSalesman(e.target.value);
    }

    const handleTechnicianChange = (e) => {
      setChosenTechnician(e.target.value);
    }

    const renderSalesmen = () => {
        console.log(salesmen)
        return salesmen.map(sm => (
            <MenuItem value={sm.employee_name}>{sm.employee_name}</MenuItem>
        ));
    }

    const renderTechnicians = () => {
      return technicians.map(tech => (
        <MenuItem value={tech.employee_name}>{tech.employee_name}</MenuItem>
      ))
    }

    const renderStatuses = () => {
      if (props.isEditing) { // job already created, can set to ordered
        // return statuses.map(status => (
        //   <MenuItem value={status}>{status}</MenuItem> 
        // ));
        if (props.editable.status == "Pending") {
          return [<MenuItem disabled value="Pending">Pending</MenuItem>, <MenuItem value="Ordered">Ordered</MenuItem>]
        } else if (props.editable.status == "Ordered") {
          return [<MenuItem disabled value="Ordered">Ordered</MenuItem>, <MenuItem value="Closed">Closed</MenuItem>]
        }
      } else {
        return <MenuItem value="Pending">Pending</MenuItem>
      }

    }

    const onCustomerChange = (customer) => {
        setChosenCustomer(customer);
        setEnabled(false);
    }

    const noOptionsCallback = (e) => {
      setEnabled(true);
      setChosenCustomer(e.inputValue);
      return "No customers found. Press button to add new customer."

    }

    // function handleNewCustomer() {
    //     setCustomers(prev => [...prev, {label: chosenCustomer, value: chosenCustomer}]);
    // }

    const handleErrorClose = () => {
      setErrorMsg("");
    }


    const renderFormFields = () => {
        let formFieldsArray = [];
        for (const field in props.formFields) {
            const propField = props.formFields[field];
            if (propField.type == 'text' || propField.type == 'email' || propField.type == 'tel') {
                formFieldsArray.push(
                    <TextField
                        value={formFields[field]}
                        autoFocus
                        margin="dense"
                        id={propField.id}
                        label={propField.label}
                        type={propField.type}
                        fullWidth
                        variant="filled"
                        size="normal"
                        onChange={handleFormChange}
                        />)
            }
        }

        
        formFieldsArray.push(
          <div>
              <InputLabel id={"salesman-select-label"}>{"Salesman"}</InputLabel>
              <Select sx={{width: "100%"}}
              labelId="demo-simple-select-label"
              id={"salesman"}
              value={chosenSalesman}
              label={"Salesman"}
              onChange={handleSalesmanChange}
              >
              {renderSalesmen()}
              </Select>
          </div>
          )

          formFieldsArray.push(
            <div>
                <InputLabel id={"technician-select-label"}>{"Technician"}</InputLabel>
                <Select sx={{width: "100%"}}
                labelId="demo-simple-select-label"
                id={"technician"}
                value={chosenTechnician}
                label={"Technician"}
                onChange={handleTechnicianChange}
                >
                {renderTechnicians()}
                </Select>
            </div>
            )


            formFieldsArray.push(
              <div className='select-div'>
                  <InputLabel id={"status-select-label"}>{"Status"}</InputLabel>
                  <Select sx={{width: "100%"}}
                  labelId="demo-simple-select-label"
                  id={"status"}
                  value={chosenStatus}
                  label={"Status"}
                  onChange={(e) => setChosenStatus(e.target.value)}
                  disabled={props.isEditing && chosenStatus == "Closed"}
                  >
                  {renderStatuses()}
                  </Select>
              </div>
              )


        const chooseCustomer = (
            <div className='choose-customer-container'>
                <label>Customer Name</label>
                <div className='search-or-add'>
                    <Select2 value={chosenCustomer}
                        options={customers}
                        onChange={onCustomerChange}
                        onMenuOpen={() => setMargin(true)}
                        onMenuClose={() => setMargin(false)}
                        defaultValue={props.isEditing ? chosenCustomer : ""}
                        className={addMargin ? "customer-name-select" : "customer-name-select-closed"}
                        noOptionsMessage={noOptionsCallback}
                        placeholder={"Select existing customer or add new"}>
                            
                    </Select2>
                    {/* <IconButton onClick={handleNewCustomer} sx={addMargin ? {height: 30, mt: 1.7, ml: 1} : {height: 30, mt: 0.5, ml: 1}} disabled={!enabled}>
                      <PersonAddIcon></PersonAddIcon>
                    </IconButton> */}

                </div>

            </div>
        )

        formFieldsArray.splice(2, 0, chooseCustomer);

        return formFieldsArray;
    }

    return (
        <div>
        {!props.editable && <Button variant="outlined" 
                                    onClick={handleClickOpen}
                                    sx={{':hover': {
                                        bgcolor: '#387cd4',
                                        color: 'white'
                                        },
                                        fontWeight: "bold",
                                        bgcolor: "white",
                                        width: 200
                                        }
                                        }>
            {props.formType}
        </Button>}
        <Dialog open={(props.isEditing ? true : open)} fullWidth={true} onClose={handleClose} maxWidth={'md'}>
            <DialogTitle>{props.formTitle}</DialogTitle>
            <DialogContent className='add-customer-modal-container'>
                {renderFormFields()}
                <ErrorPopUp onClose={handleErrorClose} msg={errorMsg}></ErrorPopUp>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleFormSubmit}>{props.isEditing ? "Apply Changes" : "Add"}</Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}