import * as React from 'react';
import { useState, useEffect } from 'react';
import RadioButtons from '../components/RadioButtons/RadioButtons';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

// import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import CellMenu from '../components/CellMenu';
import DynamicForm from '../components/DynamicForm/DynamicForm';
import AddInquiryForm from '../components/AddInquiryForm/AddInquiryForm';
import SearchBar from '../components/SearchBar/SearchBar';
import CommentsView from '../components/CommentsView/CommentsView';
import io from 'socket.io-client';
import logo from "../../assets/blue-gray-logo.png";
import jsPDF from 'jspdf';
import AmiriFont from "../../assets/Amiri Regular-normal";
import CircularProgress from '@mui/material/CircularProgress';
import Dropdown from '../components/DropdownBar/Dropdown';
import { flexbox } from '@mui/system';
import { useNavigate } from 'react-router-dom';



const InquiriesTable = ({
  inquiries, employeeName, employeeJobTitle,  handleSelect, handleDelete, handleEdit, handleInquiryToJob, isTableLoading
}) => {


  function preventDefault(event) {
    event.preventDefault();
  }

  const handleCellDelete = (id) => {
    handleDelete(id);
  }

  const handleCellEdit = (id) => {
    handleEdit(id);
  }

  const handleselectedInquiry = (id) => {
    handleSelect(id);
  }

  const turnInquiryToJob = (id, customerName) => {
    handleInquiryToJob(id, customerName);
  }

  const parseDate = (date) => {
    const currDate = new Date(date);
    currDate.setHours(currDate.getHours() + 3);
    return currDate.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' });
    
    return currDate;
    return new Date(date)
    .toLocaleString('en-US', { timeZone: 'Asia/Riyadh' });
  }

  const renderRows = () => {  
    let filteredInquiriesByEmployee = inquiries;
    if (employeeJobTitle != "admin") {
      filteredInquiriesByEmployee = inquiries.filter(inquiry => inquiry.created_by == employeeName);
    }
      if (filteredInquiriesByEmployee) {
        return inquiries.map((inquiry) => (
          <TableRow key={inquiry.quota_id}>
            <TableCell align='center'>{inquiry.quota_id}</TableCell>
            <TableCell>{parseDate(inquiry.date_created)}</TableCell>
            <TableCell sx={{minWidth: 125}}>{inquiry.inq_desc}</TableCell>
            <TableCell>{inquiry.customer_name}</TableCell>
            <TableCell>{inquiry.customer_email}</TableCell>
            <TableCell>{inquiry.customer_phone}</TableCell>
            <TableCell>{inquiry.created_by}</TableCell>
            <CellMenu id={inquiry.quota_id} handleSelect={handleselectedInquiry} onClick={handleselectedInquiry} handleEdit={handleCellEdit} handleDelete={handleCellDelete} inqMove={true} handleInquiryToJob={() => turnInquiryToJob(inquiry.quota_id, inquiry.customer_name)}/>
          </TableRow>
        ));
      }
  }

  return(
    <React.Fragment>
    <Title>Current Inquiries</Title>
    {inquiries ? 
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Quota Number</TableCell>
          <TableCell>Date Created</TableCell>
          <TableCell>Inquiry</TableCell>
          <TableCell>Customer Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Telephone</TableCell>
          <TableCell>Created By</TableCell>
        </TableRow>
      </TableHead>
      {isTableLoading ? <CircularProgress/> : 
        <TableBody>
          {renderRows()}
        </TableBody>
      }
    </Table>
    : <div>No entries available.</div>}
  </React.Fragment>
  );
}


const Inquiries = (props) => {

  const inquiryFormFields = { 
  inquiry_title: {
    id: "inq_desc",
    label: "Inquiry",
    type: "text",
  },
  salesman: {
    id: "salesman",
    label: "Salesman",
    type: "select"
  },
  technician: {
    id: "technician",
    label: "Technician",
    type: "select"
  },
  status: {
    id: "status",
    label: "Status",
    type: "select"
  }
};


const filterOptions = [{
    label: "Quota Number",
    value: "quota_id",
  }, {
    label: "Date Created",
    value: "date_created"
  }, {
    label: "Inquiry",
    value: "inq_desc"
  }, {
    label: "Customer Name",
    value: "customer_name"
  }, {
    label: "Created By",
    value: "created_by"
  }
]

  const [inquiries, setInquiries] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const [manualPage, setManualPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [isTableLoading, setLoadingTable] = useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    // const socket = io(`https://alinmaa-backend-nodejs-cleardb.herokuapp.com`, {auth: {
    //   token: authToken
    // }});

    fetchInquiries(search, selectedFilter, currentPage);

    // TODO: FIX SOCKETS
    // socket.on('inquiryUpdate', () => {
    //   fetchInquiries(search, selectedFilter, currentPage);
    // });

    // return () => {
    //   socket.close();
    // };
  }, []);


  const fetchInquiries = async(search, selectedFilter, currentPage) => {

    setLoadingTable(true);

    const options = {
      method: 'GET',
      credentials: 'include'
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/inquiries/getInquiries?search=${search}&filter=${selectedFilter}&page=${currentPage}`, options)
        .then((res) => {
          if (res.status == 403) {
            alert("Session expired. Redirecting to login for re-authentication.")
            setTimeout(() => navigate("/"), 2000);
          }
          if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.statusText}`)
          }
          return res;
        })
        .then((res) => res.json())
        .then(json => {
          setInquiries(json);
          setTotalPages(json ? Math.ceil(json.length / 30) : 1)
        })
        .catch(err => alert(err));

    setLoadingTable(false);
  }

  const handleCustomerAddition = async(customer) => {
    const addCustomerUrl = `${process.env.REACT_APP_API_URL}/api/customers/addCustomer`;

    const body = {
      customer_name: customer.name,
      contact_name: customer.contact_name ? customer.contact_name : "-",
      customer_email: customer.email ? customer.email : "-",
      customer_tel: customer.tel ? customer.tel : "-",
      customer_address: customer.address ? customer.address : "-"
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }

    const response = await fetch(addCustomerUrl, options);
        if (response.status == 403) {
        alert("Session expired. Redirecting to login for re-authentication.")
        setTimeout(() => navigate("/"), 2000);
        } else {
        const errorText = await response.text();
        alert(`Failed to add customer: ${errorText}`);
        }
  }

  const deleteInquiry = async(id) => {

    const deleteInquiryUrl = `${process.env.REACT_APP_API_URL}/api/inquiries/deleteInquiry`;

    const body = {
      quota_id: id
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }

    const response = await fetch(deleteInquiryUrl, options);
    if (response.status == 403) {
    alert("Session expired. Redirecting to login for re-authentication.")
    setTimeout(() => navigate("/"), 2000);
    } else if (response.ok) {
      console.log("deleted inquiry successfully")
    }
    else {
    const errorText = await response.text();
    alert(`Failed to move delete inquiry: ${errorText}`);
    }
  }


  const handleInquiryAddition = async(inquiry) => {

    if (inquiry.isNewCustomer) {
        handleCustomerAddition({
            name: inquiry.customer_name,
            tel: inquiry.customer_phone,
            email: inquiry.customer_email
        })
    }

    const addInquiryUrl = `${process.env.REACT_APP_API_URL}/api/inquiries/addInquiry`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: 'include',
      body: JSON.stringify(inquiry),
    }

    const response = await fetch(addInquiryUrl, options);
    if (response.ok) {  
      const responseData = await response.json();
      inquiry.quota_id = responseData.quota_id;
      inquiry.date_created = responseData.date_created;
      setInquiries(prev => [inquiry, ...prev]);
    } else if (response.status == 403) {
        alert("Session expired. Redirecting to login for re-authentication.")
        setTimeout(() => navigate("/"), 2000);
      } else {
      const errorText = await response.text();
      alert(errorText);
    }
  }

  const handleCellEdit = async(id) => {
    setIsEditing(true);
  }

  const applyCellEdits = async(editedInquiry) => {
    const editInquiryUrl = `${process.env.REACT_APP_API_URL}/api/inquiries/editInquiry`;
    editedInquiry.quota_id = selectedInquiry.quota_id;
    console.log(editedInquiry)

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' 
      },
      credentials: 'include',
      body: JSON.stringify(editedInquiry),
    }

    const response = await fetch(editInquiryUrl, options);
    if (response.ok) {  
      const updatedInquiries = inquiries.map(inquiry => {
        if (inquiry.quota_id == editedInquiry.quota_id) {
          return editedInquiry
        }
        return inquiry;
      });
      setInquiries(updatedInquiries);
      console.log(updatedInquiries)
    } else if (response.status == 403) {
      alert("Session expired. Redirecting to login for re-authentication.")
      setTimeout(() => navigate("/"), 2000);
    }
    
    else {
      const errorText = await response.text();
      alert(`Error editing inquiry: ${errorText}`);
    }
  }

  const handleCellDelete = async(id) => {
    const deleteInquiryUrl = `${process.env.REACT_APP_API_URL}/api/inquiries/deleteInquiry`;

    let body = {
        "quota_id": id
      };

    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' 
        },
        credentials: 'include',
        body: JSON.stringify(body)
    }

    const response = await fetch(deleteInquiryUrl, options);
    if (response.ok) {
        setInquiries(inquiries.filter(inquiry => inquiry.quota_id != id));
    } else if (response.status == 403) {
        alert("Session expired. Redirecting to login for re-authentication.")
        setTimeout(() => navigate("/"), 2000);
    }
    
    else {
        const errorText = await response.text();
        alert(`Failed to delete inquiry: ${errorText}`);
    }
  }

  const handleselectedInquiry = (id) => {
    const inquiry = inquiries.filter(inquiry => inquiry.quota_id == id)[0];
    setSelectedInquiry({
      quota_id: inquiry.quota_id,
      date_created: inquiry.date_created,
      inq_desc: inquiry.inq_desc,
      customer_name: inquiry.customer_name,
      customer_phone: inquiry.customer_phone,
      customer_email: inquiry.customer_email,
      created_by: inquiry.created_by,
    });
  }

  const handleSearch = (searchQry) => {
    if (selectedFilter) {
      setCurrentPage(1);
      setManualPage(1);
      setSelectedSearch(searchQry);
      fetchInquiries(searchQry, selectedFilter, currentPage);
    } else {
      alert("Choose a search filter!");
    }
  }

  const handleInquiryToJob = (id, customerName) => {
    deleteInquiry(id);
    navigate("/jobs");
    props.onInquiryToJob(id, customerName)
  }

  const parseNumDate = (date) => {
    return new Date(date).toLocaleString('en-US', {timeZone: 'Etc/GMT+4', day: 'numeric', month: 'numeric', year: 'numeric'});
  }


  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  }

  const goToPage = (e, page) => {
      if ((e.target.innerText == "Prev" && currentPage != 1) || (e.target.innerText == "Next" && currentPage != totalPages)) {
        setCurrentPage(page);
        setManualPage(page);
        fetchInquiries(selectedSearch, selectedFilter, page);
      }
    }

    const handleManualPageChange = (e) => {
      let page = parseInt(e.target.value);
      if (page > totalPages) {
        page = totalPages;
      } else if (page < 1) {
        page = 1;
      }
      if (!isNaN(page)) {
        setManualPage(page);
      } else {
        setManualPage("");
      }
    }
  
    const handleManualPageClick = (e) => {
      if (e.key == "Enter" && manualPage != "") {
        setCurrentPage(manualPage);
        fetchInquiries(search, selectedFilter, manualPage);
      }
    }
      
        return (
          <ThemeProvider theme={createTheme()}>
            <Box sx={{ display: 'flex', width: "100%" }}>
              <CssBaseline />
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                }}
              >
                <Toolbar />
                
                <div className='search-add-container-jobs'>

                  <div className='search-filter-container'>
                    <SearchBar className='search-bar'
                                placeholder = 'Search'
                                onChange={(value) => setSearch(value.toLowerCase())}
                                onSearch={handleSearch}
                                value={search}
                        />


                      {props.isMobile ? <Dropdown selected={selectedFilter} onSelect={handleFilterChange} selections={filterOptions}></Dropdown> : null}
                      {props.isMobile ? null :             
                        <div className='filter-container'>
                          <Container sx={{ width: 800, mr: "auto", ml: -1.25, mt: 0, boxShadow: 3, height: 40, bgcolor: "white", borderRadius: 2, display: "flex", alignItems: "center"}}>
                                    <RadioButtons id={"jobs"}  options={filterOptions} value={selectedFilter} onChange={handleFilterChange}></RadioButtons>
                          </Container>
                        </div>
                      }

                    </div>

                  <div className='add-customer-button'>
                    {console.log(props.userCreds.user_name)}
                    <AddInquiryForm formType={"Add inquiry"}
                                formTitle={"Add inquiry"} 
                                formFields={inquiryFormFields}
                                employeeName={props.userCreds.user_name}
                                onSubmit={handleInquiryAddition}>

                    </AddInquiryForm>
                  </div>
                </div>
                <div className='row-containers'>

                  <Container maxWidth="xlg" sx={{ mt: 4, mb: 4, ml: "auto", mr: "auto"}}>
                    <Grid container spacing={3} zeroMinWidth>
                      {/* Recent Orders */}
                      <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflow: "scroll" }}>
                          <InquiriesTable inquiries={inquiries}
                                          searchQry={search}
                                          filter={selectedFilter} 
                                          handleEdit={handleCellEdit}
                                          handleDelete={handleCellDelete}
                                          handleSelect={handleselectedInquiry}
                                          handleInquiryToJob={handleInquiryToJob}
                                          employeeName={props.userCreds.user_name}
                                          employeeJobTitle={props.userCreds.job_title}
                                          isLoading={isTableLoading}/>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Container>
                </div>



                {isEditing && <AddInquiryForm type={"edit"} 
                                          isEditing={isEditing} 
                                          formTitle={"Edit inquiry"} 
                                          formFields={inquiryFormFields} 
                                          employeeName={props.userCreds.user_name}
                                          editable={selectedInquiry} 
                                          onEditClose={() => setIsEditing(false)}
                                          onSubmit={applyCellEdits}>
                              </AddInquiryForm>}

                <div className='pagination-bar'>
                  <p className={currentPage === 1 ? "pag-button-disabled" : "pag-button"} onClick={(e) => goToPage(e, currentPage - 1)}>
                    Prev
                  </p>

                  <div className='pagination-page-num'>{`Page `}
                    
                      <input type="number"
                      className='no-arrows'
                      value={manualPage}
                      onChange={(e) => handleManualPageChange(e)}
                      onKeyDown={(e) => handleManualPageClick(e)}
                      min="1"
                      max={totalPages}
                      style={{ textAlign: 'center' }}
                    />
                    
                    
                    {` of ${totalPages}`}
                  </div>

                  <div>
                    <p className={currentPage == totalPages ? "pag-button-disabled" : "pag-button"} onClick={(e) => goToPage(e, currentPage + 1)} disabled={currentPage == totalPages}>
                      Next
                    </p>
                  </div>
                </div>

              </Box>
            </Box>
          </ThemeProvider>
        );

}

export default Inquiries;