import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorPopUp from '../ErrorPopUp/ErrorPopUp';
import { useEffect } from 'react';

export default function DynamicForm(props) {
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    let startingState = {};
    for (const prop in props.formFields) {
        startingState[prop] = "";
    }



    const [formFields, setFormFields] = useState(startingState);

    useEffect(() => {
        if (props.isEditing) {
            setFormFields(props.editable);
        }
    }, [props.isEditing]);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setFormFields(startingState);
        setOpen(false);
        if (props.isEditing) {
            props.onEditClose();
        }
    };

    const handleFormChange = (e) => {
        const target = e.target.id;
        const value = e.target.value;
        const prev = {...formFields};
        prev[target] = value;
        console.log(`target = ${target} and value = ${value}`);
        setFormFields(prev);
    }

    const handleFormSubmit = async() => {
        for (const prop in formFields) {
          if (formFields[prop].length < 1) {
            setErrorMsg("Please fill all fields to submit");
            return;
          }
        }
        props.onSubmit(formFields);
        handleClose();
    }

    const handleErrorClose = () => {
      setErrorMsg("");
    }

    const renderFormFields = () => {
        let formFieldsArray = [];
        for (const field in props.formFields) {
            formFieldsArray.push(
                <TextField
                    value={formFields[field]}
                    autoFocus
                    margin="dense"
                    id={props.formFields[field].id}
                    label={props.formFields[field].label}
                    type={props.formFields[field].type}
                    fullWidth
                    variant="filled"
                    size="normal"
                    onChange={handleFormChange}
                    />)
        }
        return formFieldsArray;
    }

    return (
        <div>
        {!props.editable && <Button variant="outlined" 
                                    onClick={handleClickOpen}
                                    sx={{':hover': {
                                        bgcolor: '#387cd4',
                                        color: 'white'
                                        },
                                        fontWeight: "bold",
                                        bgcolor: "white",
                                        width: 200}    
                                    }>
            {props.formType}
        </Button>}
        <Dialog open={(props.isEditing ? true : open)} onClose={handleClose} maxWidth={'xl'}>
            <DialogTitle>{props.formTitle}</DialogTitle>
            <DialogContent className='add-customer-modal-container'>
                {renderFormFields()}
                <ErrorPopUp msg={errorMsg} onClose={handleErrorClose}></ErrorPopUp>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleFormSubmit}>{props.isEditing ? "Apply Changes" : "Add"}</Button>
            </DialogActions>
        </Dialog>
        </div>
    );
    

}