import React, { useState, useRef } from 'react';
import { MenuItem, FormControl, Select, Button, Box } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './Reports.css';
import RubikFont from "../assets/Rubik Regular-normal";
import AmiriFont from "../assets/Amiri Regular-normal";
import logo from "../assets/blue-gray-logo.png";
import { fontSize } from '@mui/system';

const Reports = ({
    userCreds, isMobile
}) => {

    const navigate = useNavigate();

    const [reportType, setReportType] = useState('daily');
    const [pdfUrl, setPdfUrl] = useState("");

    const dailyReportText = `Generate a report for the outstanding confirmed (ordered) jobs for the current day.`;
    const monthlyReportText = `Generate a monthly report for the pending / confirmed / closed jobs for a specific month.`;

    const [reportData, setReportData] = useState();



    const handleReportTypeChange = (event) => {
        setReportType(event.target.value);
    };

    const constructDailyReport = async() => {

        const options = {
          method: 'GET',
          credentials: 'include'
        }
    
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jobs/getDailyReport`, options);

        if (response.ok) {
            const data = await response.json();
            setReportData(data);
            return data;

        } else if (response.status == 403) {
            alert("Session expired. Redirecting to login for re-authentication.")
            setTimeout(() => navigate("/"), 2000);
        } else {
            alert(response.err);
            return;
        }
    
    }

    const generatePDF = async () => {

        const x = await constructDailyReport();

        // create new PDF, A4, portrait
        const doc = new jsPDF({
            orientation: 'p',
            format: 'a4',
        });

        var width = doc.internal.pageSize.getWidth();

        // add logo
        const headerImg = new Image();
        headerImg.src = logo;
        doc.addImage(headerImg, 'png', 130, 0, 75, 35);

        // add title and date
        const now = new Date();

        doc.text("Daily Job Report", 10, 15);
        doc.setFontSize(11);
        doc.text(`Generated at ${now.toLocaleString()}`, 10, 25);
        doc.text(`Created for: ${userCreds.user_name}`, 10, 30);

        // Dummy data - replace this with actual data fetching logic
        console.log(doc.getFontList());

        doc.autoTable({
            head: [['Job ID', 'Title', 'Customer Name', 'Technician', 'Value', 'Date Ordered', 'Invoice Num', 'Expected Days to Complete', 'Days Post Order']],
            body: x.map(item => [item.job_id, item.job_title, item.customer_name, item.technician, item.job_value, item.date_ordered, item.invoice_num, item.expected_eta, "3"]),
            startY: 50,
            headStyles: {font: "Amiri Regular", fontSize: 8},
            bodyStyles: {font: "Amiri Regular", fontSize: 8}
        });


        // Format today's date
        const today = new Date();
        const dateString = today.toISOString().substring(0, 10);

        doc.setProperties({title: `Daily Job Report - ${dateString}`})

        // Save the created PDF into a blob
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <div className='reports-container'>
                <div className='select-button-wrapper'>
                    <FormControl fullWidth>
                        <Select
                            value={reportType}
                            onChange={handleReportTypeChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                        <MenuItem value="daily">Daily Report</MenuItem>
                        <MenuItem value="monthly">Monthly Report</MenuItem>
                        </Select>
                    </FormControl>
                <Button variant="contained" onClick={generatePDF} sx={{marginLeft: 3, width: 300}}>
                    Generate Report
                </Button>
            </div>
            <p className='report-description'>{reportType == "daily" ? dailyReportText : monthlyReportText}</p>
            {pdfUrl && 
                <embed
                    className='report-pdf'
                    title="PDF Report"
                    src={pdfUrl}
                />
            }

        </div>
    )
}

export default Reports;