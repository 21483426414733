import { Outlet, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const ProtectedRoute = () => {
    
    const [isLoggedIn, setLoggedIn] = useState(null);

    useEffect(() => {
        const isUserAuthenticated = async() => {
        
            // call simple api in the backend
            console.log("Reached Protected Route! Verifying Authentication...");
            const checkAuthUrl = `${process.env.REACT_APP_API_URL}/api/auth/checkAuth`;
            const options = {
                method: "GET",
                credentials: "include"
            }
    
            const response = await fetch(checkAuthUrl, options);
            console.log(response.ok)
            setLoggedIn(response.ok);
        }

        isUserAuthenticated();
    }, [])

    if (isLoggedIn == null) {
        return (<div>Checking Authentication...</div>)
    }

    {console.log(isLoggedIn)}
    return(
        isLoggedIn ? <Outlet/> : <Navigate to="/"/>
    )
}

export default ProtectedRoute;