import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import "../Login.css";
import Logo from "../assets/blue-logo.png";
import { useNavigate } from "react-router-dom";
import ErrorPopUp from "./components/ErrorPopUp/ErrorPopUp";
import CircularProgress from '@mui/material/CircularProgress';

const Login = (props) => {


  // React States
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false)
  // User Login info

  const handleErrorClose = () => {
    setErrorMsg("");
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  }

  const handleSubmit = async(event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    if (uname.value.length < 1) {
      console.log("asdas")
      setErrorMsg("Please enter a username");
      return;
    } else if (pass.value.length < 1) {
      setErrorMsg("Please enter a password");
      return;
    }

    setIsLoading(true);
    console.log(process.env.REACT_APP_API_URL)
    //const authURL = `${process.env.REACT_APP_API_URL}/api/auth/login`;
    const authURL = `${process.env.REACT_APP_API_URL}/api/auth/login`;
    //const authURL = `${process.env.REACT_APP_API_URL}/login`;

    let creds = {
      "username": uname.value,
      "password": pass.value
    }

    console.log(creds);

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      credentials: "include",
      body: JSON.stringify(creds)
    }

    const loginResponse = await fetch(authURL, options)
    if (loginResponse.ok) {
      const data = await loginResponse.json()
      setIsLoading(false);
      console.log(data)
      props.onLogin(data);
      navigate('/home');
      console.log("logging in...");
    } else {
      setIsLoading(false);
      setErrorMsg(await loginResponse.text())
    }
  };

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form>
        <div className="input-container">
          <label className="field-label">Username </label>
          <input className="login-user" type="text" name="uname" required />
        </div>
        <div className="input-container">
          <label className="field-label">Password </label>
          <input className="login-pass" type="password" name="pass" required />
        </div>
      </form>
    </div>
  );

  return (
    <div className="app">
      <div className="login-form" onKeyDown={handleKeyDown}>
        <img className="login-logo" src={Logo} alt="alinmaa-logo"></img>
        <p className="subtitle"><span className="subtitle-admin">Admin</span> Portal</p>
        {/* <div className="title">Log In</div> */}
        {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
        <ErrorPopUp onClose={handleErrorClose} msg={errorMsg}></ErrorPopUp>
        <div className="button-container" onClick={handleSubmit}>
          {isLoading ?  <CircularProgress color="primary" /> : <input type="submit" value={"Log in"}/> }
        </div>
      </div>
    </div>
  );
}

export default Login;