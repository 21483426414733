import React from "react";
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Select from '@mui/material/Select';
import Select2 from 'react-select';
import { IconButton } from '@mui/material';
import ErrorPopUp from "../ErrorPopUp/ErrorPopUp";
import { useNavigate } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import CircularProgress from '@mui/material/CircularProgress';
import "./AddInquiryForm.css";


const AddInquiryForm = ({
    formType,
    formTitle,
    employeeName,
    isEditing,
    editable,
    onEditClose,
    onSubmit,
}) => {

    console.log(employeeName)


    const [open, setOpen] = useState(false);

    const [salesmen, setSalesmen] = useState([]);
    const [chosenSalesman, setChosenSalesman] = useState("");

    const [inquiryDescription, setInquiryDescription] = useState("");
    const [customers, setCustomers] = useState([]);
    const [chosenCustomer, setChosenCustomer] = useState("");
    const [chosenCustomerEmail, setChosenCustomerEmail] = useState("");
    const [chosenCustomerPhone, setChosenCustomerPhone] = useState("");

    const [isLoadingCustomerInfo, setIsLoadingCustomerInfo] = useState(false);

    const [enabled, setEnabled] = useState(false);
    const [addMargin, setMargin] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        const fetchSalesmen = async() => {
            const options = {
              method: 'GET',
              credentials: 'include'
            }
            fetch(`${process.env.REACT_APP_API_URL}/api/employees/getSalesmen`, options)
              .then((res) => {
                if (res.status == 403) {
                  navigate("/");
                }
                if (!res.ok) {
                  throw new Error(`Failed to fetch: ${res.statusText}`)
                }
                return res;
              })
              .then((res) => res.json())
              .then(json => setSalesmen(json))
              .catch(err => console.log(err))
          }

          const fetchCustomers = async() => {
            const options = {
              method: 'GET',
              credentials: 'include'
            }

            fetch(`${process.env.REACT_APP_API_URL}/api/customers/getCustomerNames`, options)
                .then((res) => {
                  if (res.status == 403) {
                    navigate("/");
                  }
                  if (!res.ok) {
                    throw new Error(`Failed to fetch: ${res.statusText}`)
                  }
                  return res;
                })
                .then((res) => res.json())
                .then(json => setCustomers(json.map(customer => ({
                    label: customer.customer_name,
                    value: customer.customer_name
                }))))
                .catch(err => console.log(err))
          }
          
      
          fetchSalesmen();
          fetchCustomers();
    }, []);

    useEffect(() => {
        if (isEditing) {
            setInquiryDescription(editable.inq_desc);
            setChosenCustomer({label: editable.customer_name, value: editable.customer_name});
            setChosenCustomerEmail(editable.customer_email);
            setChosenCustomerPhone(editable.customer_phone);
            //ssetChosenCustomerContact(editable.customer_contact);
        }
    }, [isEditing]);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setInquiryDescription("");
        setChosenCustomer("");
        setChosenCustomerEmail("");
        setChosenCustomerPhone("");
        setErrorMsg("");
        setOpen(false);
        if (isEditing) {
            onEditClose();
        }
    };


    const handleInquiryDescriptionChange = (e) => {
        setInquiryDescription(e.target.value);
    }

    const handleCustomerEmailChange = (e) => {
      setChosenCustomerEmail(e.target.value);
    }

    const handleCustomerPhoneChange = (e) => {
      setChosenCustomerPhone(e.target.value);
    }


    function delay(ms) {
      // Return a promise that resolves after 'ms' milliseconds
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    const fetchCustomerInformation = async(customerName) => {
      setIsLoadingCustomerInfo(true);

      const getCustomerInformationUrl = `${process.env.REACT_APP_API_URL}/api/customers/getCustomerByName?${new URLSearchParams({customer_name: customerName})}`;

      const options = {
        method: 'GET',
        credentials: 'include'
      }
  
      const response = await fetch(getCustomerInformationUrl, options)

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        setChosenCustomerEmail(data.customer_email);  
        setChosenCustomerPhone(data.customer_tel);

      } else {
        setErrorMsg("Error loading customer data.");
        alert(`Could not load customer data: ${response.err}`)
      }

      setIsLoadingCustomerInfo(false);
    }

    const handleFormSubmit = async() => {
        if (inquiryDescription.length < 1) {
          setErrorMsg("Please fill inquiry description ");
          return;
        }
        else if (chosenCustomer == "") {
          setErrorMsg("Please choose a customer. If this is a new customer, please add it through the customers tab.");
          return;
        }

        else if (chosenCustomerEmail == "" && chosenCustomerPhone == "") {
          setErrorMsg("Please add atleast one contact");
          return;
        }
        

        const newInquiry = {
          inq_desc: inquiryDescription,
          customer_name: chosenCustomer.value,
          customer_email: chosenCustomerEmail,
          customer_phone: chosenCustomerPhone,
          created_by: employeeName,
        }

        onSubmit(newInquiry);
        handleClose();
    }

    const renderSalesmen = () => {
        return salesmen.map(sm => (
            <MenuItem value={sm.employee_name}>{sm.employee_name}</MenuItem>
        ));
    }

    const onCustomerChange = (customer) => {
        setChosenCustomer(customer);
        setEnabled(false);
        fetchCustomerInformation(customer.value)
    }

    const handleErrorClose = () => {
      setErrorMsg("");
    }


    return (
        <div>
        {!editable && <Button variant="outlined" 
                                    onClick={handleClickOpen}
                                    sx={{':hover': {
                                        bgcolor: '#387cd4',
                                        color: 'white'
                                        },
                                        fontWeight: "bold",
                                        bgcolor: "white",
                                        width: 200
                                        }
                                        }>
            {formType}                                    
        </Button>}
        <Dialog open={(isEditing ? true : open)} fullWidth={true} onClose={handleClose} maxWidth={'md'}>
            <DialogTitle>{formTitle}</DialogTitle>
            <DialogContent className='add-customer-modal-container'>

                <TextField
                        value={inquiryDescription}
                        autoFocus
                        margin="dense"
                        required={true}
                        id={"inquiry-desc-field"}
                        label={"Inquiry"}
                        type={"text"}
                        fullWidth
                        variant="filled"
                        size="normal"
                        onChange={handleInquiryDescriptionChange}
                />

                <div className='choose-customer-container'>
                    <label>Customer Name</label>
                    <div className='search-or-add'>
                        <CreatableSelect value={chosenCustomer}
                            options={customers}
                            required={true}
                            onChange={onCustomerChange}
                            onMenuOpen={() => setMargin(true)}
                            onMenuClose={() => setMargin(false)}
                            defaultValue={isEditing ? chosenCustomer : ""}
                            className={addMargin ? "customer-name-select" : "customer-name-select-closed"}
                            placeholder={"Select existing customer"}
                            >
                        </CreatableSelect>
                    </div>
                </div>

                {isLoadingCustomerInfo ? <CircularProgress color="primary" /> : null}
                
                <p className="inquiry-customer-text">If inquiry has different contact info, please edit the fields below.</p>
                
                <TextField
                        value={chosenCustomerPhone}
                        autoFocus
                        margin="dense"
                        id={"inquiry-desc-field"}
                        disabled={isLoadingCustomerInfo}
                        label={"Telephone"}
                        type={"number"}
                        fullWidth
                        variant="filled"
                        size="normal"
                        onChange={handleCustomerPhoneChange}
                />    


                <TextField
                        value={chosenCustomerEmail}
                        autoFocus
                        margin="dense"
                        id={"inquiry-desc-field"}
                        disabled={isLoadingCustomerInfo}
                        label={"Email"}
                        type={"email"}
                        fullWidth
                        variant="filled"
                        size="normal"
                        onChange={handleCustomerEmailChange}
                />                                            
                <ErrorPopUp onClose={handleErrorClose} msg={errorMsg}></ErrorPopUp>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleFormSubmit}>{isEditing ? "Apply Changes" : "Add"}</Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}

export default AddInquiryForm;